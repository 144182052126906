window.jQuery = $;
const $ = require('jquery');
window.$ = $;
window['j'+'Query'] = $; // keep this stupid syntax to cheat with webpack Encore autoinject jquery
require('fancybox')($);
require('slick-carousel');
require('jquery.mb.ytplayer');
require('jquery.counterup');
require('./imagesloaded.pkgd.min');
require('bootstrap');
require('responsive-tabs');


import objectFitImages from 'object-fit-images';
import { jarallax } from 'jarallax'
import Isotope from 'isotope-layout'
import toastr from 'toastr'

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "8000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}
window.toastr = toastr;

(function($) {
    'use strict';
    $(window).on('load', function() {
        function addNewClass() {
            $('.fxt-template-animation').imagesLoaded().done(function (instance) {
                $('.fxt-template-animation').addClass('loaded');
            });
        }
        addNewClass();
    });
    // Login Effect
    jQuery(document).ready(function () {
        if (jQuery.fn.YTPlayer !== undefined && jQuery("#fxtVideo").length) {
            jQuery("#fxtVideo").YTPlayer({useOnMobile:true});
        }

        objectFitImages();

        $(".toggle-password").on('click', function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });

        function asideDropdown() {

            var dropdown = $('.aside-dropdown');

            if (!dropdown.length) return;

            var trigger = $('.dropdown-trigger');
            var	close = $('.aside-dropdown__close');
            var introLink = $('.aside-dropdown .main-menu__link--scroll');

            trigger.on('click', function(){
                dropdown.addClass('aside-dropdown--active');
            });

            close.on('click', function(){
                dropdown.removeClass('aside-dropdown--active');
            });

            introLink.on('click', function(){
                dropdown.removeClass('aside-dropdown--active');
            });

            $(document).on('click', function(event) {
                if ( $(event.target).closest('.dropdown-trigger, .aside-dropdown__inner').length) return;
                dropdown.removeClass('aside-dropdown--active');
                event.stopPropagation();
            });

        }
        asideDropdown();

        function videoTrigger() {

            var trigger = $('.video-trigger');

            if (!trigger.length) return;

            trigger.fancybox();

        }
        videoTrigger();

        $('.alert__close').on('click', function () {
            $(this).parent('.alert').fadeOut(300);
        });

        function accordion () {

            var accordion = $('.accordion');

            if (!accordion.length) return;

            var close = $('.accordion .accordion__close');

            close.on('click', function () {
                $(this).toggleClass('accordion__close--active').parents().children('.accordion__text-block').stop().slideToggle(300);
            });

        }
        accordion ();

        function parallax() {

            var parallax = document.querySelectorAll('.jarallax');

            if (!jarallax.length) return;

            jarallax(parallax, {
                speed: 0.5
            });


        }
        parallax();

        function tabs() {

            var tabs = $('.tabs');

            if (!tabs.length) return;

            tabs.responsiveTabs({
                startCollapsed: 'false'
            });

        }
        tabs();

        function masonryProjects() {

            var masonryProjects = $('.projects-masonry');

            if (!masonryProjects.length) return;

            var iso = new Isotope('.projects-masonry', {
                itemSelector: '.projects-masonry__item',
                percentPosition: true,
            });

        }
        masonryProjects();

        function masonryGallery() {

            var masonryGallery = $('.gallery-masonry');

            if (!masonryGallery.length) return;

            var iso = new Isotope('.gallery-masonry', {
                itemSelector: '.gallery-masonry__item',
                percentPosition: true,
            });

            var filter = $('.filter-panel__item');

            filter.on('click', function () {

                var filterValue = $(this).attr('data-filter');

                var iso = new Isotope('.gallery-masonry', {
                    filter: filterValue ,
                });

            });

            filter.on('click', function () {

                filter.removeClass('filter-panel__item--active');
                $(this).addClass('filter-panel__item--active');

            });

        }
        masonryGallery();

        function rangeSlider () {

            var rangeSlider = $('.range-slider .range-slider__bar');

            if (!rangeSlider.length) return;

            var min = $('.range-slider__min');
            var	max = $('.range-slider__max');

            rangeSlider.ionRangeSlider({
                type: 'double',
                min: 0,
                max: 1200,
                from: 50,
                to: 900,
                skin: 'round',
                step: 10,
                onChange: function (data) {

                    min.val(data.from);
                    max.val(data.to);

                }
            });

        }
        rangeSlider();

        function quantity() {

            var count = $('.cart-item__count');

            if (!count.length) return;

            var	minus = $('.cart-item__minus');
            var	plus = $('.cart-item__plus');

            minus.on('click', function () {

                var $input = $(this).parent().find('input');
                var count = parseInt($input.val()) - 1;
                count = count < 1 ? 1 : count;
                $input.val(count);
                $input.change();
                return false;

            });

            plus.on('click', function () {

                var $input = $(this).parent().find('input');
                $input.val(parseInt($input.val()) + 1);
                $input.change();
                return false;

            });

        }
        quantity();

        function formQuantity() {

            var count = $('.form__count');

            if (!count.length) return;

            var	minus = $('.form__minus');
            var	plus = $('.form__plus');

            minus.on('click', function () {

                var $input = $(this).parent().find('input');
                var count = parseInt($input.val()) - 1;
                count = count < 1 ? 1 : count;
                $input.val(count);
                $input.change();
                return false;

            });

            plus.on('click', function () {

                var $input = $(this).parent().find('input');
                $input.val(parseInt($input.val()) + 1);
                $input.change();
                return false;

            });

        }
        formQuantity();

        function select() {

            var select = $('.form__select');

            if (!select.length) return;

            select.niceSelect({
            });

        }
        select();

        function asideTrigger() {

            var trigger = $('.shop__aside-trigger');

            if (!trigger.length) return;

            trigger.on('click', function () {

                $('body').find('.aside-holder').toggleClass('aside-holder--visible');
                $('body').find('.shop__backdrop').toggleClass('shop__backdrop--visible');

            });

            var close = $('.shop__aside-close');

            close.on('click', function () {

                $('body').find('.aside-holder').removeClass('aside-holder--visible');
                $('body').find('.shop__backdrop').removeClass('shop__backdrop--visible');

            });

            var backdrop = $('.shop__backdrop');

            backdrop.on('click', function () {
                $(this).removeClass('shop__backdrop--visible');
                $('body').find('.aside-holder').removeClass('aside-holder--visible');
            });

        }
        asideTrigger();

        $('.aside-menu__item--has-child a').on('click', function () {

            $(this).parent().children('.aside-menu__sub-list').stop().slideToggle(300);

        });

        function scrollTo () {

            var scrollTo = $('a.anchor[href^="#"]');

            if (!scrollTo.length) return;

            scrollTo.on("click", function (e) {

                var anchor = $(this);

                $('html, body').stop().animate({
                    scrollTop: $(anchor.attr('href')).offset().top
                }, 600);

                e.preventDefault();

            });

        }
        scrollTo();

        function scrollToId() {

            var scroll = $('a.main-menu__link--scroll');

            if(!scroll.length) return;

            scroll.mPageScroll2id({
                highlightClass: 'main-menu__link--highlighted',
            });

        }
        scrollToId();

        function promoSlider () {

            var promoSlider = $('.promo-slider');

            if (!promoSlider.length) return;

            var status = $('.promo-slider__count');
            var	prev = $('.slider__nav--promo .slider__prev');
            var	next = $('.slider__nav--promo .slider__next');


            promoSlider.on('init afterChange', function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                status.text(i + '/' + slick.slideCount);
            });

            promoSlider.slick({
                fade: true,
                autoplay: true,
                autoplaySpeed: 7000,
                adaptiveHeight: false,
                infinite: true,
                speed: 1200,
                prevArrow: prev,
                nextArrow: next,
            });

        }
        promoSlider ();

        function dualSlider() {

            var slider = $('.main-slider');

            if (!slider.length) return;

            slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                asNavFor: '.nav-slider',
                fade: true,
            });

            var navSlider = $('.nav-slider');

            navSlider.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                asNavFor: '.main-slider',
                focusOnSelect: true,
                arrows: false,
            });

        }
        dualSlider();

        function projectsSlider() {

            var projectsSlider = $('.projects-slider');

            if (!projectsSlider.length) return;

            var prev = $('.projects-slider__nav .slider__prev');
            var next = $('.projects-slider__nav .slider__next');

            projectsSlider.slick({
                slidesToShow: 3,
                prevArrow: prev,
                nextArrow: next,
                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }]
            })

        }
        projectsSlider();

        function relatedSlider () {

            var relatedSlider = $('.related-slider');

            if (!relatedSlider.length) return;

            var prev = $('.related-slider__nav .slider__prev');
            var next = $('.related-slider__nav .slider__next');

            relatedSlider.slick({

                slidesToShow: 4,
                slidesToScroll: 1,
                prevArrow: prev,
                nextArrow: next,

                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                }, {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                }, {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                }]

            });

        }
        relatedSlider();

        function donorsSlider() {

            var donorsSlider = $('.donors-slider');
            var	styleOne = $('.donors-slider--style-1');
            var	styleTwo = $('.donors-slider--style-2');

            if (!donorsSlider.length) return;

            styleOne.slick({
                autoplay: true,
                autoplaySpeed: 100,
                speed: 2500,
                arrows: false,
                dots: false,
                slidesToShow: 4,
                adaptiveHeight: true,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }
                }, {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                }]
            });

            styleTwo.slick({
                rows: 2,
                slidesPerRow: 4,
                arrows: false,
                dots: true,
                adaptiveHeight: true,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        rows: 2,
                        slidesPerRow: 3,
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        rows: 2,
                        slidesPerRow: 2,
                    }
                }, {
                    breakpoint: 765,
                    settings: {
                        rows: 2,
                        slidesPerRow: 1,
                    }
                }]
            });

        }
        donorsSlider();

        function causesSlider() {

            var causesSlider = $('.causes-slider');

            if (!causesSlider.length) return;

            var prev = $('.causes-slider__nav .slider__prev');
            var	next = $('.causes-slider__nav .slider__next');

            causesSlider.slick({
                slidesToShow: 3,
                prevArrow: prev,
                nextArrow: next,
                responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }]
            });

        }
        causesSlider();

        function testimonialsFirst () {

            var testimonials = $('.testimonials-slider--style-1');

            if (!testimonials.length) return;

            var prev = $('.testimonials-style-1__nav .slider__prev');
            var	next = $('.testimonials-style-1__nav .slider__next');

            testimonials.slick({
                prevArrow: prev,
                nextArrow: next,
                adaptiveHeight: true,
            });

        }
        testimonialsFirst ();

        function testimonialsSecond () {

            var testimonials = $('.testimonials-slider--style-2');

            if (!testimonials.length) return;

            var prev = $('.testimonials-style-2__nav .slider__prev');
            var	next = $('.testimonials-style-2__nav .slider__next');

            testimonials.slick({
                prevArrow: prev,
                nextArrow: next,
                adaptiveHeight: true,
            });

        }
        testimonialsSecond ();

        function testimonialsThird () {

            var testimonials = $('.testimonials-slider--style-3');

            if (!testimonials.length) return;

            var prev = $('.testimonials-style-3__nav .slider__prev');
            var	next = $('.testimonials-style-3__nav .slider__next');

            testimonials.slick({
                prevArrow: prev,
                nextArrow: next,
                adaptiveHeight: true,
                slidesToShow: 3,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }],
            });

        }
        testimonialsThird ();

        function itemsSlider() {

            var itemsSlider = $('.items-slider');

            if (!itemsSlider.length) return;

            var prev = $('.items-slider__nav .slider__prev');
            var	next = $('.items-slider__nav .slider__next');

            itemsSlider.slick({
                prevArrow: prev,
                nextArrow: next,
                fade: true,
            });

        }
        itemsSlider();

        function pagesSlider() {

            var slider = $('.pages-slider');

            if(!slider.length) return;

            var prev = $('.pages-slider__nav .slider__prev');
            var	next = $('.pages-slider__nav .slider__next');

            slider.slick({
                slidesToShow: 3,
                prevArrow: prev,
                nextArrow: next,
                adaptiveHeight: true,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                }]
            });

        }
        pagesSlider();

        function initMenuSticker() {
            var header = $('header.header');
            var scroll = $(window).scrollTop();
            if (scroll >= 1) {
                header.addClass('header--fixed');
            } else {
                header.removeClass('header--fixed');
            }
        }
        initMenuSticker();

        jQuery(window).scroll(function(){
            initMenuSticker();
        });
    });
    // CoockieConsent
    window.addEventListener('load', function () {
        var cookieconsent = initCookieConsent();
        cookieconsent.run({
            autorun: true,
            current_lang: 'fr',
            autoclear_cookies: true,
            page_scripts: true,
            languages: {
                fr: {
                    consent_modal: {
                        title: 'Cookies',
                        description: 'En poursuivant votre navigation sur IMEA, vous acceptez l’utilisation de cookies servant à mesurer l’audience',
                        primary_btn: {
                            text: 'J\'accepte',
                            role: 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Préférences',
                            role: 'settings'
                        }
                    },
                    settings_modal: {
                        title: 'Préférences d\'utilisation',
                        save_settings_btn: 'Sauvegarder les préférences',
                        accept_all_btn: 'Tout accepter',
                        reject_all_btn: 'Tout refuser',       // optional, [v.2.5.0 +]
                        cookie_table_headers: [
                            {col1: 'Nom'},
                            {col2: 'Domaine'},
                            {col3: 'Expiration'},
                            {col4: 'Description'},
                            {col5: 'Type'}
                        ],
                        blocks: [
                            {
                                title: 'Utilisation des cookie',
                                description: 'IMEA utilise des cookies pour assurer les fonctionnalités de base du site Web et pour améliorer votre expérience en ligne. Vous pouvez choisir pour chaque catégorie de vous inscrire ou de vous désinscrire quand vous le souhaitez.'
                            }, {
                                title: 'Cookies strictement nécessaires',
                                description: 'Ces cookies sont indispensables au bon fonctionnement de mon site internet. Sans ces cookies, le site Web ne fonctionnerait pas correctement.',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true
                                }
                            }, {
                                title: 'Analytics cookies',
                                description: 'Ces cookies collectent des informations sur la façon dont vous utilisez le site Web, les pages que vous avez visitées et les liens sur lesquels vous avez cliqué. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier.',
                                toggle: {
                                    value: 'analytics',
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [
                                    {
                                        col1: '^_ga',
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'Cookie google anlytics',
                                        col5: 'Permanent',
                                        is_regex: true
                                    }
                                ]
                            }, {
                                title: 'Plus d\'information',
                                description: 'Pour plus d\'information n\'hésitez pas à nous contacter sur <a class="cc-link" href="mailto:imea.dpo@imea.fr">imea.dpo@imea.fr</a>.',
                            }
                        ]
                    }
                }
            }
        });
    });
}(jQuery));
